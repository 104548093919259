@import "assets/styles/_variables";

/* width */

body {
  color: $black;
  font-family: $rotondaC;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}

h1 {
  font-family: $rotondaC;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-family: $rotondaB;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}

h3 {
  font-family: $rotondaC;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 27px;
}
@import "assets/styles/_common.scss";
@import "assets/styles/popup/popup.scss";
