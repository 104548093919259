//font mixin
@mixin font20 {
  font-size: 20px;
  line-height: 22px;
  @include ipad {
    font-size: 16px;
    line-height: 18px;
  }
}

@mixin font24 {
  font-size: 24px;
  line-height: 27px;
  @include ipad {
    font-size: 16px;
    line-height: 18px;
  }
}

@mixin font16 {
  font-size: 16px;
  line-height: 17px;
  @include ipadMini {
    font-size: 14px;
    line-height: 15px;
  }
}