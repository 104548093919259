@import '~assets/styles/variables';

.Overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba($black, .7);
  z-index: 11111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 15px;
    margin-bottom: 24px;
  }
}