@import "~assets/styles/_variables.scss";

footer {
  min-height: 68px;
  width: 100%;
  @include padding-how-header;
  background: $violet;
  color: $white;
}
.Footer {
  @include container;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include ipadMini {
    justify-content: center;
  }
}
.Socials {
  @include ipadMini {
    padding: 24px 0;
    @include icon-text-gap;
    border-top: 1px solid $white;
    width: 100%;
    margin-top: 30px;
    padding-left: 20px;
  }
  a {
    margin: 0 12px;
  }
}
.Buttons {
  display: flex;
  align-items: center;

  margin-right: 60px;
  > * {
    cursor: pointer;
  }
}
.FondyLink {
  margin-right: 10px;
  @include ipadMini {
    display: none;
  }
}
