//Mixins
@import "font";
@import "breakpoints";
@import "forms";

@mixin icon-text-gap {
  margin-right: 16px;
  @include ipad {
    margin-right: 8px;
  }
}

@mixin padding-how-header {
  padding: 16px 50px;
  @include macbook {
    padding: 16px 40px;
  }
  @include ipad {
    padding: 24px 20px;
  }
  @include ipadMini{
    padding: 16px;
  }
}
@mixin padding-how-registration  {
  padding: 40px 134px;
  @include macbook{
    padding: 40px;
  }
  @include ipad{
    padding: 20px;
  }
}

@mixin rectangle($width, $height, $color, $rotate, $top, $left) {
  width: $width;
  height: $height;
  background: $color;
  transform: $rotate;
  content: '';
  display: block;
  top: $top;
  left: $left;
  position: absolute;
  border-radius: $border-radius;
  opacity: .6;
  z-index: -1;
}

@mixin container {
  max-width: 1900px;
  margin: 0 auto;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    @include ipadMini{
      width: 4px;
    }
  }
  &::-webkit-scrollbar-track {
    margin: 10px 0;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($violet, .8);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $violet;
  }

}

