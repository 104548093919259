@mixin macbook { //1439px => 1024px
  @media(max-width: $MacBook - 1px) {
    @content;
  }
}

@mixin ipad { //1023px => 768px
  @media(max-width: $iPad - 1px) {
    @content;
  }
}

@mixin ipadMini { //767px => 480px
  @media(max-width: $iPadMini - 1px) {
    @content
  }
}

@mixin iphone { //479px => 320px
  @media(max-width: $iPhone -1px) {
    @content;
  }
}
@mixin mobile {
  @media(max-width: $iPhone - 1px) {
    @content;
  }
}

