@import "~assets/styles/variables";

.ErrorPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  &Wrap{
    position: relative;
    background: $white;
    font-family: $rotondaC;
    z-index: 1;
  }
  header {
    @include container;
    @include padding-how-header;
    width: 100%;
  }

  &Content {
    @include padding-how-header;
    @include container;
    width: 100%;
  }

  &Status {
    z-index: 1;
    font-size: 128px;
    line-height: 136px;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: bold;
    color: $blue;
    position: relative;
    &:after {
      @include rectangle(350px, 187px, $grey, rotate(-13deg) translateX(-50%), -80px, 50%)
    }
    @include ipadMini{
      transform: scale(.7);
    }
  }

  &Description {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      text-align: center;
      @include font16;
      line-height: 160.6%;
      &:last-child{
        margin-bottom: 16px;
      }
    }
  }
}

.Logo {
  padding-top: 40px;
  img{
    width: 210px;
  }
  display: block;
@include ipadMini{
  padding-top: 0;
}
}