//Colors
$white: #fff;
$grey: #E6E9F3;
$violet: #6A5EA4;
$blue: #4A4DA4;
$black: #212529;
$orange: #E69C50;
$light-pink: #D8658A;
$pink: #E9407A;
$dark-pink: #8F4A68;

//Font-family
$rotondaC: RotondaC, sans-serif;
$rotondaB: Rotonda Bold, sans-serif;

// Another styles
$border-radius: 40px;

//Breakpoints
$MacBook: 1440;
$iPad: 1024px;
$iPadMini: 768px;
$iPhone: 480px;


//Button
$radius-button: 28px;
$padding-btn: 16px 32px;
$padding-btn-small: 8px 24px;



@import "mixins/mixins";

