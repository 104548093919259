@import "~assets/styles/_variables.scss";
@import "~assets/styles/_icons.scss";

.dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
  @include ipad {
    text-decoration: none;
  }
}

.always-dashed {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, $white 50%, rgba(255, 255, 255, 0) 0%);
    background-size: 10px 3px;
  }

}
.md-hide {
  display: none!important;
  @include ipad{
    display: flex!important;
  }
}
.xs {
  display: none!important;
  @include ipadMini{
    display: block!important;
  }
}
.no-xs {
  @include ipadMini{
    display: none!important;
  }
}
.column {
  display: flex;
  flex-direction: column;
}

.pb16 {
  padding-bottom: 16px;
}
