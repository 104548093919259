@import "assets/styles/_variables.scss";

.Button {
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius-button;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  line-height: 25px;
  opacity: 1;
  transition: all .3s ease;
  cursor: pointer;
  flex-shrink: 0;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  &Thin {
    box-shadow: none !important;
    border-radius: 8px;
    font-size: 20px;
    line-height: 22px;
    height: 44px;
    padding: 0 24px;
    font-weight: 500;

    &Small {
      font-weight: 500;
      font-size: 16px;
      line-height: 44px;
    }

    a, span {
      padding: 0 !important;
    }
  }

  a, span {
    padding: $padding-btn;
  }

  &Small {
    a, span {
      padding: $padding-btn-small;
    }

    font-size: 20px;
    line-height: 21px;
  }

  &:hover {
    opacity: .8;
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
}