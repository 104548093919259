:root {
    --white: #fff;
    --grey: #E6E9F3;
    --violet: #6A5EA4;
    --blue: #4A4DA4;
    --black: #212529;
    --orange: #E69C50;
    --light-pink: #D8658A;
    --pink: #E9407A;
    --dark-pink: #8F4A68;
}
@font-face {
    font-family: 'RotondaC';
    src: url('~assets/fonts/RotondaC.woff') format('woff'),
    url('~assets/fonts/RotondaC.ttf') format('truetype'),
    url('~assets/fonts/RotondaC.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RotondaC';
    src: url('~assets/fonts/RotondaC-Bold.woff') format('woff'),
    url('~assets/fonts/RotondaC-Bold.ttf') format('truetype'),
    url('~assets/fonts/RotondaC-Bold.otf') format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
.App {
    overflow-x: hidden;
    box-sizing: border-box;
}