.popup {
  background: $blue;
  box-shadow: 10px 10px 0 0 rgba($blue, .6);
  border-radius: 10px;
  max-width: 602px;
  width: 100%;
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  color: $white;
  text-align: center;
  position: relative;
  @include ipadMini {
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0;
  }

  &.grey {
    color: $black;
    background: $white;
    box-shadow: 10px 10px 0 0 rgba($white, .6);
  }

  &__icon {
  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    width: 40px;
    height: 40px;
    background: rgba($white, .6);
    border-radius: 10em;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include ipadMini {
      top: 10px;
      right: 10px;
    }

    &.grey {
      background-color: rgba($grey, .6);
      transition: background-color .35s ease;

      &:hover {
        background-color: $grey;
      }
    }

    svg {
      width: 100%;
      height: 100%;

    }
  }

  &__text {
    max-width: 474px;
  }
}