@import '~assets/styles/variables';

@keyframes rotate-preloader {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-5deg);
  }
  22% {
    transform: rotate(185deg);
  }
  25%,
  50% {
    transform: rotate(180deg);
  }

  55% {
    transform: rotate(185deg);
  }

  72% {
    transform: rotate(-5deg);
  }

  75%,
  100% {
    transform: rotate(0deg);
  }
}

@mixin donsAnimation($name, $padding) {
  @keyframes #{$name} {
    0%,
    25%,
    95%,
    100% {
      transform: translateY(0);
    }
    45%,
    50%,
    75% {
      transform: translateY($padding);
    }
    47.5% {
      transform: translateY($padding + 1px);
    }
    97.5% {
      transform: translateY(-1px);
    }
  }
}

@include donsAnimation('space-1', -5px);
@include donsAnimation('space-2', -9px);
@include donsAnimation('space-3', -13px);

$animation: 3.5s infinite linear;

.loader {
  width: 200px;
  animation: rotate-preloader $animation;

  .small-2,
  .big-3 {
    animation: space-1 $animation;
  }

  .small-1,
  .big-2 {
    animation: space-2 $animation;
  }

  .small-3,
  .big-1 {
    animation: space-3 $animation;
  }

  .border,
  .separator,
  .section-separator {
    rect {
      fill: $black;
    }
  }

  .bg rect {
    fill: #fff;
  }

  .small-1,
  .small-2,
  .small-3,
  .big-1,
  .big-2,
  .big-3 {
    rect {
      stroke-width: 0.25px;
      stroke: rgba($black, 0.5);
    }
  }

  .small-1,
  .big-1 {
    rect {
      fill: #faa43a;
    }
  }
  .small-2,
  .big-2 {
    rect {
      fill: #ef5181;
    }
  }

  .small-3,
  .big-3 {
    rect {
      fill: #6d4a98;
    }
  }
}