.i {
  &-lamp {
    @include ipadMini {
      transform: translateX(-60px) translateY(50px) rotate(14.44deg)!important;
      left: 100%!important;
    }
  }

  &-calc {
    @include ipadMini {
      left: 0!important;
      transform: translateX(-5px) translateY(50px) rotate(-23deg) !important;

    }
  }

  &-line {
    @include ipadMini {
      top: auto !important;
      bottom: -80px !important;
      left: 80% !important;
    }
  }
}