
.PreloaderPage {
  &Wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 10001;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--white);
  }
}

.loader {
  border: 16px solid var(--grey); /* Light grey */
  border-top: 16px solid var(--blue); /* Blue */
  border-radius: 50%;
  width: 160px;
  height: 160px;
  animation: spin 800ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}