@import "~assets/styles/variables";

.Loading {
  display: flex;
  align-items: center;
  min-height: 80px;
  justify-content: center;
  position: relative;
  width: 100%;

  &Overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    top: 0;
    background-color: rgba($white, .7);
    min-height: unset;

    svg {
      position: relative;
      transform: none;
    }
  }

  svg {
    width: 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
  }
}